import * as React from 'react'
import {useState} from 'react';
import Layout from "../components/layout";

import {graphql, Link} from "gatsby";
import Consumer from "../context";

import WeiboIcon from '../img/social_media/sinaweibo.svg'
import WeChatIcon from '../img/social_media/wechat.svg'
import XiaoHongShuIcon from '../img/social_media/xiaohongshu.svg'
import BilibiliIcon from '../img/social_media/bilibili.svg'

import QR_weibo from '../img/qr/qr_weibo.svg'
import QR_wechat from '../img/qr/qr_wechat.svg'
import QR_xiaohongshu from '../img/qr/qr_xiaohongshu.svg'
import QR_bilibili from '../img/qr/qr_bilibili.svg'
import Markdown from "markdown-to-jsx";
import {SEO} from "../components/seo";

interface Props {
    data: {
        strapiContactInformation: {
            ContactDetails: {
                data: {
                    ContactDetails: string
                }
            }
        }
    }
}

let social_details = [
    {
        icon: WeiboIcon,
        name: '微博',
        url: '',
        qr: QR_weibo
    },
    {
        icon: WeChatIcon,
        name: '微信',
        url: '',
        qr: QR_wechat
    },    {
        icon: XiaoHongShuIcon,
        name: '',
        url: '',
        qr: QR_xiaohongshu
    },    {
        icon: BilibiliIcon,
        name: 'Bilibili',
        url: '',
        qr: QR_bilibili
    },
]

const ContactPage = ({data}: Props) => {
    let mailto = "mailto:eastasia@universityofgalway.ie"

    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };

    const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setBody(event.target.value);
    };

    return (
        <Layout>
            <Consumer>
                {({ data, set }) => (
                    (data.category === 'contact') ? console.log(data.category) :  set({ category: 'contact' })
                )}
            </Consumer>

            <section className="text-gray-800 body-font relative">
                <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className="lg:w-2/3 md:w-1/2 p-8">
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mb-8 mt-4 px-4">
                            {
                                social_details.map( (item)=>(
                                        <>
                                            <div className="flex flex-col items-center">
                                                <div className="flex justify-center items-center bg-galway-maroon text-white h-8 w-full gap-2">
                                                    <img src={item.icon} className="h-5" />
                                                    <p>{item.name}</p>
                                                </div>
                                                <img className="border-b-8 border-b-magenta" src={item.qr} />
                                            </div>
                                        </>
                                    )
                                )
                            }
                        </div>
                        <div className="bg-gray-100 rounded-lg overflow-hidden p-8 flex items-end justify-start relative h-96">
                            <iframe width="100%" height="100%" className="absolute inset-0 p-4 rounded-lg"
                                    scrolling="no"
                                    src="https://www.bing.com/maps/embed?h=600&w=800&cp=53.207996~-9.050800&lvl=11&typ=d&sty=r&src=SHELL&FORM=MBEDV8"/>
                            <div className="bg-white relative flex flex-wrap p-6 mt-10 rounded shadow-md">
                                <div className="lg:w-1/2 px-6">
                                    <h2 className="title-font text-sm font-semibold tracking-widest">地址</h2>
                                    <p className="mt-1 text-xs">
                                        University of Galway,<br />
                                        University Road,<br />
                                        Galway, Ireland<br />
                                        H91 TK33
                                    </p>
                                </div>
                                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                    <h2 className="title-font text-sm font-semibold text-gray-900 tracking-widest">电子邮件</h2>
                                    <a className="text-galway-maroon leading-relaxed text-xs">eastasia@universityofgalway.ie</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full  mt-8 md:mt-0">
                        <article className="prose prose-slate prose-h2:text-galway-maroon">
                            <Markdown>{data.strapiContactInformation.ContactDetails.data.ContactDetails}</Markdown>
                        </article>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">标题</label>
                            <input type="email" id="email" name="email"
                                   value={subject}
                                   onChange={handleSubjectChange}
                                   className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="message" className="leading-7 text-sm text-gray-600" >正文</label>
                            <textarea id="message" name="message"
                                      value={body}
                                      onChange={handleBodyChange}
                                      className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                        {/*@ts-ignore*/}
                        <Link to='#'
                              onClick={(e) => {
                                  let link = mailto + "?subject=abc" + subject + "&body=" + body
                                  window.location.href = link;
                                  e.preventDefault();
                              }}
                            className="text-white text-center bg-galway-maroon border-0 py-2 px-6 focus:outline-none hover:bg-magenta rounded text-lg">
                            提交
                        </Link>
                        <p className="text-xs text-gray-800 mt-3">邮件将通过您的邮件客户端发送。</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage

export const Head = () => (
    <SEO title="联系我们" description="欢迎通过社交媒体联系我们，访问爱尔兰高威大学的官方微信，微博，小红书和哔哩哔哩。" />
)

export const query = graphql`
    query ContactInformation {
      strapiContactInformation {
        ContactDetails {
          data {
            ContactDetails
          }
        }
      }
    }
`
